import './Details.css';
import React, { useState } from 'react';

import { Stack, Container, Grid } from '@mui/material';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

import lorenz from '../../assets/lorenzXZ.svg';

import lorenzProjection1a from '../../assets/lorenzProjection1a.svg';
import lorenzProjection1b from '../../assets/lorenzProjection1b.svg';
import lorenzProjection2b from '../../assets/lorenzProjection2b.svg';
import lorenzProjection2c from '../../assets/lorenzProjectedSol_pi3_-pi3.svg';

const Math = (props) => {
  const mathJaxConfig = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [["$", "$"]],
      displayMath: [["$$", "$$"]]
    }
  };

  return (
    <MathJaxContext config={mathJaxConfig} version={3}>
      <MathJax inline>
        {props.expr}
      </MathJax>
    </MathJaxContext>
  );
}


const About = () => {

  return (
    <div className="details">
      <div className="textblock">

        <h4>What are attractors?</h4>
        <p>
          In dynamical systems, an attractor is a set of states towards which a system tends to evolve for a wide variety of starting conditions.
          Consider the pendulum as a simple example for such a dynamic system. Once set in motion, it will gradually lose energy due to friction and eventually reach the resting position again. In other words, the pendulum's state is pulled towards its resting position - the attractor of the system.
        </p>

        <p>
          Strange attractors set themselves apart by having fractal structure and often emerge from chaotic systems, which makes them especially interesting to visualise.
          Chaotic systems are very sensitive to their initial conditions, meaning that two arbitrarily close points can become arbitrarily far apart as the system evolves - a phenomenon also known as the butterfly effect.
          A dynamic system with a chaotic attractor is therefore locally unstable yet globally stable: once the system has entered the attractor, nearby states diverge from one another but never depart from the attractor.
        </p>


        {/* <p className="sectionTitle">What's new?</p>
        <p>
          The non-linear coupling in chaotic dynamical systems makes it impossible to solve their evolution analytically.
          Instead, numerical methods have to be employed to simulate and study their behaviour.
          While this has already been done in the past, this project is the first to realise the solution of multi-dimensional differential equations and their visualisation in-chain using nothing but solidity.
          There are no external dependencies other than a browser to view the resulting SVG graphic.
          With this, I am trying to push the limits of what is currently possible with smart-contracts and demonstrate that Solidity is able to handle complex numerical algorithms that are usually implemented using other languages.
          <br />
          As an example, the above picture shows a chaotic Lorenz attractor computed entirely by my smart-contract.
        </p> */}


        <h4>A prominent example: The Lorenz Attractor</h4>
        <p>
          One of the most famous strange attractors is the Lorenz attractor, a three-dimensional structure that projected resembles a butterfly or a mask (shown below).
          It is named after Edward Lorenz, an American mathematician who discovered the attractor while developing and studying a mathematical model to describe the Earth's atmosphere.
        </p>

        <Grid container spacing={0} className="lorenz-example">
          <Grid item xs={10} md={6}>
            <img src={lorenz} />
          </Grid>
        </Grid>

        <p>
          The model can be understood in simple terms by imagining an upright rectangular slice through our atmosphere that is heated by the Earth from below and cooled from above by the void of outer space.
          Within this slice, air can move freely driven by thermal convection, where warm air rises and cold air sinks.
          In this model, the average state of the atmosphere can be described in three dimensions by:
          <ul>
            <li><Math expr={"$x$"} />, the rate of convection</li>
            <li><Math expr={"$y$"} />, the horizontal temperature variation</li>
            <li><Math expr={"$z$"} />, the vertical temperature variation</li>
          </ul>
          The evolution of these quantities is governed by a coupled set of ordinary differential equations, the so called Lorenz system:
          {/* <Math expr={lorenzSystem} /> */}
          <p className="lorenzSystem">
            <Stack direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 5 }}>
              <Math expr={String.raw`$\dot{x} = \sigma (y  - x)$`} />
              <Math expr={String.raw`$\dot{y} =  x (\rho - z) - y$`} />
              <Math expr={String.raw`$\dot{z} = xy - \beta z$`} />
            </Stack>
          </p>
          The symbols <Math expr={String.raw`$\beta, \rho, \sigma$`} /> correspond to the free parameters of the system, describing important properties of the atmospheric slice:
          <ul>
            <li><Math expr={String.raw`$\rho$`} />, the temperature difference between the top and the bottom of slice</li>
            <li><Math expr={String.raw`$\sigma$`} />, the ratio of viscosity to thermal conductivity </li>
            <li><Math expr={String.raw`$\beta$`} />, the width-to-height ratio of the slice</li>
          </ul>

          The shown Lorenz attractor was computed using the classical parameters <Math expr={String.raw`$\rho = 28$`} />, <Math expr={String.raw`$\sigma = 10$`} />, <Math expr={String.raw`$\beta = 8/3$`} />, and <Math expr={'$x=y=z=10$'} /> as the starting point.
          For the visualisation, the solution was projected to the <Math expr={'$x-z$'} /> plane.
        </p>


        <h4>How are the images generated?</h4>
        <p>
          First, the evolution of a dynamical system is simulated using a numerical scheme that will be introduced at a later stage.
          Since every state of the system can be visualised as a point in three-dimensional space, the evolution of such a system can be represented as a three-dimensional path.
          Using the Lorenz system again as an example, such a path would look similar to the cyan curve in the image below.
        </p>
        <p>
          Secondly, the dimensionality of the data has to be reduced due to the limitations of the SVG format, which can only be used to visualise two-dimensional data.
          For this we rely on a procedure known as orthogonal projection, which effectively flattens a three-dimensional structure onto a given projection plane as shown below.
          Put simply, this can also be understood as viewing the three-dimensional curve from a given point-of-view.
        </p>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          className="projectionStack"
        >
          <Container maxWidth="sm">
            <img src={lorenzProjection1a} className="lorenzProjection" />
          </Container>

          <Container maxWidth="sm">
            <img src={lorenzProjection1b} className="lorenzProjection" />
          </Container>
        </Stack>

        <p>
          The exact orientation of the plane is completely arbitrary and will result in a broad variety of different visualisations.
          Once minted, the holder of a piece can freely choose the projection parameters of their token.
          As an example, the projection plane has been tilted and rotated by 60 degrees which results in an entirely different figure for the same Lorenz attractor.
          The possibilities are virtually infinite.
        </p>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          className="projectionStack"
        >
          <Container maxWidth="sm">
            <img src={lorenzProjection2b} className="lorenzProjection" />
          </Container>

          <Container maxWidth="sm">
            <img src={lorenzProjection2c} />
          </Container>
        </Stack>

        <p>
          For the nerds among us:
          The projection plane is spanned by two independent vectors.
          This means that the projection plane can not only be oriented as desired but can also include skewed non-unitary basis vectors to introduce additional distortions.
          This enables more general projections and gives the holders even more degrees of freedom to customise their visualisations.
        </p>

        <p>
          Mathematically speaking, the location of a projected point <Math expr={String.raw`$\vec{\eta} = (x',y')^T$`}/> is computed using the transformation
        <Math expr={String.raw`$$ \vec{\eta} = \pmatrix{\vec{a}^T_1 \\ \vec{a}^T_2} \left( \vec{\xi} - \Delta \vec{\xi} \right),$$`} />
        where <Math expr={String.raw`$\vec{\xi} = (x,y,z)^T$`}/> is the original three-dimensional point,   <Math expr={String.raw`$\Delta \vec{\xi}$`}/> an offset and <Math expr={String.raw`$\vec{a}_{1,2}$`}/> the two projection vectors.
        </p>

      </div>
    </div >
  );
}

export default About;
