import React, { useState } from 'react';

export const ConnectButton = ({account, handleAccountsChanged}) => {
    const connect = () => {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log('Please connect to MetaMask.');
          } else {
            console.error(err);
          }
        });
    }

    return (<div className="wallet-connect">
      <span>Wallet: </span>
      {account === "" &&
        <input type="button" onClick={connect} value="Connect" className="button"></input>
      }
      {account !== "" && <span>0x{account.slice(2).toUpperCase()}</span>}

    </div>);
  }



export default ConnectButton;