import './SvgWindow.css'

export const SvgWindow = ({ data, loading }) => {
    return (
        <div className='svg-window'>
            <div className='rendering-indicator '>
                {loading && "Rendering..."}
            </div>
            <img src={"data:image/svg+xml," + data} />
        </div>
    );
}

export default SvgWindow;