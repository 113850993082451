import './Tools.css';
import React, { useEffect, useState } from 'react';

import { TokenSelector } from '../../components/MyTools//TokenSelector';
import { ConnectButton } from '../../components/MyTools/ConnectButton';

import { ProjectionExpert, ProjectionSliders } from '../../components/ProjectionTools';

import { ColormapCreator } from '../../components/ColormapCreator';

import * as utils from '../../components/MyTools/utils.js';
import * as utils2 from '../../components/MyTools/utils2.js';
import { SvgWindow } from '../../components/SvgWindow';


import { LabeledSlider } from '../../components/MyTools/NumberSlider';



const Tools = () => {

  const [selectedToken, setSelectedToken] = React.useState(-1);
  const [svg, setSvg] = useState("%3Csvg width='1024' height='1024' viewBox='-4096 -4096 8192 8192' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-4096' y='-4096' width='100%25' height='100%25' fill='black'/%3E%3C/svg%3E");

  const [projectionParameters, setProjectionParameters] = useState({
    axis1: [1, 0, 0],
    axis2: [0, 0, 1],
    offset: [3, 3, 3],
  });
  const [colorAnchors, setColorAnchors] = useState([])
  const [renderSize, setRenderSize] = useState(20)


  const [rendering, setRendering] = useState(false);
  const [renderTrigger, setRenderTrigger] = useState(false);

  useEffect(() => {
    if (renderTrigger) {
      render();
      setRenderTrigger(false);
    }
  }, [renderTrigger]);

  const reloadToken = async (tokenId) => {
    if (!utils2.isValidTokenId(selectedToken)) {
      console.error("Invalid tokenId:", tokenId);
      return;
    }

    try {
      console.log('Loading token', tokenId);
      let projectionParameters = await utils.fetchTokenProjectionParameters(tokenId);
      setProjectionParameters(utils.parseProjectionParameters(projectionParameters));
      let colorAnchors = await utils.fetchTokenColorAnchors(tokenId);
      setColorAnchors(colorAnchors);
      let renderSize = (await utils.getToken(tokenId)).renderSize;
      setRenderSize(renderSize);
      setRenderTrigger(true);
    } catch (err) {
      console.error(err);
    }
  }

  const onTokenSelect = async (e) => {
    let tokenId = e.target.value;
    setSelectedToken(tokenId);
    if (tokenId >= 0) {
      await reloadToken(tokenId);
    }
  }

  const render = async () => {
    if (!utils2.isValidTokenId(selectedToken)) {
      console.error("Invalid tokenId:", tokenId);
      return;
    }

    setRendering(true);
    const _projParams = utils.convertProjectionParameters(projectionParameters);
    setSvg(await utils.renderToken(selectedToken, _projParams, colorAnchors, renderSize));
    setRendering(false);
  }

  // /**********************************************************/
  // /* Handle chain (network) and chainChanged (per EIP-1193) */
  // /**********************************************************/

  window.ethereum.on('chainChanged', (chainId) => window.location.reload())


  /***********************************************************/
  /* Handle user accounts and accountsChanged (per EIP-1193) */
  /***********************************************************/
  const [account, setAccount] = useState('');

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      setAccount('')
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.');
    } else if (accounts[0] !== account) {
      // currentAccount = accounts[0];
      setAccount(accounts[0])
      // Do any other work!
    }
  }

  window.ethereum.on('accountsChanged', handleAccountsChanged)

  window.ethereum
    .request({ method: 'eth_accounts' })
    .then(handleAccountsChanged)
    .catch((err) => {
      // Some unexpected error.
      // For backwards compatibility reasons, if no accounts are available,
      // eth_accounts will return an empty array.
      console.error(err);
    });


  const [resetProjection, setResetProjection] = useState(false);
  const [resetColors, setResetColors] = useState(false);
  const [resetSize, setResetSize] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);

  const doReset = () => {
    if (!utils2.isValidTokenId(selectedToken)) {
      console.error("Invalid tokenId:", tokenId);
      return;
    }

    if (resetProjection || resetColors || resetSize) {
      utils.resetRenderParameters(selectedToken, { resetProjection, resetColors, resetSize });
    } else {
      alert("Please select one or more parameters that should be reset.")
    }
  }


  return (
    <div className="tools">
      <div>
        <ConnectButton {...{ account, handleAccountsChanged }} />
        {(account !== '' && window.ethereum.chainId != 1) && <h2 className="mainnet-message">Please connect Metamask to the mainnet</h2>}


        <div className={(account == '' || window.ethereum.chainId != 1) ? "disabled" : ""}>
          <div className="tools-container">

            <div className="left">
              <SvgWindow data={svg} loading={rendering} />
              <div className="border token-selector">
                <TokenSelector onChange={onTokenSelect} account={account} />
                <div className="manual-token-select">
                  <input type="number" onInput={e => { if (e.target.value >= 0) onTokenSelect(e) }} />
                </div>
              </div>

              <div className="button-box">
                <div>
                  <input type="button" onClick={render} value="Render" className="button"></input>
                </div>
                <div>
                  <input type="button" onClick={() => utils.downloadSVG(unescape(svg))} value="Download" className="button"></input>
                </div>

                <div >
                  <input type="button" onClick={() => reloadToken(selectedToken)} value="Reload" className="button"></input>
                </div>
              </div>

              <div className="button-box">
                <div>
                  <input type="button" onClick={() => utils.uploadProjectionParameters(selectedToken, projectionParameters)} value="Commit Projection" className="button"></input>
                </div>
                <div>
                  <input type="button" onClick={() => utils.uploadColorAnchors(selectedToken, colorAnchors)} value="Commit Colors" className="button"></input>
                </div>
              </div>

              <div className="button-box">
                <div>
                  <input type="button" onClick={() => utils.uploadRenderSize(selectedToken, renderSize)} value="Commit Size" className="button"></input>
                </div>

                <div className={resetOpen ? "invert" : ""}>
                  <input type="button" onClick={() => setResetOpen(!resetOpen)} value="Reset Defaults" className="button"></input>
                </div>
              </div>



              {resetOpen &&
                <div className="button-box">

                  <div>
                    <input type="button" onClick={doReset} value="Do Reset" className="button"></input>
                  </div>

                  <div className={resetProjection ? "invert" : ""}>
                    <input type="button" onClick={(e) => setResetProjection(!resetProjection)} value="Projection?" className="button"></input>
                  </div>

                  <div className={resetColors ? "invert" : ""}>
                    <input type="button" onClick={(e) => setResetColors(!resetColors)} value="Colors?" className="button"></input>
                  </div>

                  <div className={resetSize ? "invert" : ""}>
                    <input type="button" onClick={(e) => setResetSize(!resetSize)} value="Size?" className="button"></input>
                  </div>
                </div>
              }

            </div>


            <div className="right">
              <div className="border">
                <div className="paletteTitle">Projection</div>
              </div>
              {projectionParameters.axis1.length === 3 &&
                <div className="border">
                  <ProjectionSliders {...{ projectionParameters, setProjectionParameters }} />
                </div>
              }
              <div className="border">
                <ProjectionExpert {...{ projectionParameters, setProjectionParameters }} />
              </div>
              <div className="border">
                <div className="paletteTitle ">Style</div>
              </div>
              <div className="size-slider-box border">
                <LabeledSlider label="Size" min={0} max={128} value={renderSize} onChange={(e) => setRenderSize(e.target.value)} />
              </div>
              <div className="colormap border">
                <ColormapCreator {...{ colorAnchors, setColorAnchors }} />
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}

export default Tools;
