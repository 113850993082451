import './NavBar.css';

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Box } from '@mui/material';

const InlineResponsiveSwitch = (switches) => {

  const displayProto = { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'none' };

  return (
    <span>
      {Object.keys(switches).map(
        (key, index) => {
          let disp = { ...displayProto };
          disp[key] = 'inline';
          return (
            <Box key={index} sx={{ display: disp }}>
              {switches[key]}
            </Box>
          );
        }
      )}
    </span>
  );
}



const NavBar = (props) => {
  const { match, location, history } = props;

  const setCurrentLocation = async (thisLocation) => {
    return (thisLocation == location.pathname) ? "currentLocation" : "";
  }

  return (
    <div className="navbar">
      <div className="left">
        <Link to="about">About</Link>
        {InlineResponsiveSwitch({
          xs: <br />,
          sm: ' / ',
          md: ' / ',
          lg: ' / ',
          xl: ' / ',
        })}
        <Link to="details">
          {InlineResponsiveSwitch({
            xs: 'Details',
            sm: 'Details',
            md: 'Technical Details',
            lg: 'Technical Details',
            xl: 'Technical Details',
          })}
        </Link>
      </div>

      <div className="title center">
        <Link to="/">Strange Attractors</Link>
      </div>

      <div className="right">

      <a href="mint">Mint</a>
        {InlineResponsiveSwitch({
          xs: <br/>, 
          sm: ' / ', 
          md: ' / ',
          lg: ' / ',
          xl: ' / ',
          })}
        <a href="tools">Tools</a>

        {/* <a href="https://discord.gg/SyYCfrtzkP" target="_blank">Discord</a>
        {InlineResponsiveSwitch({
          xs: <br/>, 
          sm: ' / ', 
          md: ' / ',
          lg: ' / ',
          xl: ' / ',
          })}
        <a href="https://twitter.com/StrngeAttrctors" target="_blank">Twitter</a> */}
      </div>
    </div>
  );
};


export default withRouter(NavBar);