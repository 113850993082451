import './Footer.css';

import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const Footer = (props) => {
  return (
    <div className="footer">
      <span>
      &copy; Strange Attractors / All rights reserved.
      </span>
    </div>
  );
};

export default Footer;