import './Mint.css';
import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers'

import * as utils from '../../components/MyTools/utils.js';
import { ConnectButton } from '../../components/MyTools/ConnectButton';

import Countdown from 'react-countdown';

import slotsEarly from './slotsEarly.json'

const Mint = () => {

  const [account, setAccount] = useState('');
  const [isSlotRestricted, setIsSlotRestricted] = useState(true);
  const [mintSlots, setMintSlots] = useState([]);
  const [numPiecesLeft, setNumPiecesLeft] = useState(0);

  const handleAccountsChanged = (accounts) => {
    console.log('account changed');
    if (accounts.length === 0) {
      setAccount('')
      console.log('Please connect to MetaMask.');
    } else if (accounts[0] !== account) {
      setAccount(accounts[0])
    }
  }

  if (typeof window.ethereum !== 'undefined') {

    // /**********************************************************/
    // /* Metamask boilerplate                                   */
    // /**********************************************************/
    window.ethereum.on('chainChanged', (chainId) => window.location.reload())

    window.ethereum.on('accountsChanged', handleAccountsChanged)

    window.ethereum
      .request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error(err);
      });

    const getSlotData = async () => {
      try {
        const contract = utils.getContract();
        let restricted = await contract.isSlotRestricted();
        setIsSlotRestricted(restricted);
        if (restricted) {
          setMintSlots(await utils.getValidSlots(account, slotsEarly));
        }
        setNumPiecesLeft(512 - (await contract.totalSupply()));
        console.log('mint price:', await contract.MINT_PRICE());
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(getSlotData, [account])
  }
  const canMint = () => {
    return (!isSlotRestricted && numPiecesLeft > 0) || mintSlots.length > 0;
  }

  const doMint = () => {
    try {
      let slot;
      if (isSlotRestricted) {
        slot = mintSlots.pop();
        setMintSlots(mintSlots);
      } else {
        slot = { grantee: account, nonce: 1, signature: "0xc9b053ac606abe966d87f4c47c57ef111a274362e8a19f34d85ea92702ecf17a040ece76be26e24307390219a95f009fad9110c66d3b0868f9e5baf9f2326d341b" }
      }
      utils.mint(slot);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="mint">


      <div className="textblock">

        <h2>Minting Slots</h2>
        <p>
          The actual mint will be preceded by an off-chain distribution of minting slots, that will allow you to mint a random piece without having to compete in gas wars.
        </p>

            <h4>How can I get a minting slot?</h4>

            <p>
              The slots are be distributed as follows:
              <ul>
                <li>8 pieces were be retained by myself and gifted to special people that supported this project or me in general.</li>
                <li>
                  256 slots were reserved for Discord early birds (the raffle is already over).
                </li>
                <li>2 slots were given to puzzle solvers as a reward.</li>
                <li>
                  246 slots will be raffled off in a <strong>public lottery</strong>.
                </li>
              </ul>
            </p>

            <h4>Public Lottery</h4>
            <p>
              The registration for the public lottery will open on <strong>28th October at 19:00 UTC</strong>.
              <div className="timer">
                <Countdown date={new Date("2021-10-28T19:00:00.000+00:00")}>
                  <span>Hurry up! The registration has already started.</span>
                </Countdown>
              </div>
            </p>
            <p>
              <strong>Everybody can participate</strong> in this lottery and doing so is <strong>completely free</strong>! Don't miss your chance!
            </p>

            <p>
              More details and instructions will be communicated via the  <a href="https://discord.gg/SyYCfrtzkP" target="_blank">Discord server</a> shortly before the registration opens.
            </p>

            <hr/>

        <h2>Minting</h2>

        <p>
          Minting will start on <strong>27th October at 19:00 UTC</strong> and will only be possible be possible if you have a minting slot.
          The slots can be consumed until the end of October.
          Afterwards, minting of the remaining pieces will be opened up for everyone.
        </p>

        <div className="important-numbers">
          <ul>
            <li>Mint price: 0.35&#926;</li>
            <li>Tokens: 512 (128 per system)</li>
          </ul>
          <ul>
            <li>1 Slot = 1 Mint</li>
            <li>License: <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">CC BY-NC-SA 4.0</a></li>
          </ul>
        </div>



        <div className="mint-section">
          <div>
            Connect your wallet and mint your Strange Attractor by clicking the button below.
          </div>

          <div>
            <ConnectButton {...{ account, handleAccountsChanged }} />
          </div>

          <div className="mint-container">
            <div className="mint-button-container">
              <input type="button" value="Mint Strange Attractor" className="mint-button" onClick={doMint} disabled={(account == "") || (!canMint())}></input>
            </div>

            <div className="available-slots">
              {isSlotRestricted && <span>Available minting slots: {mintSlots.length}</span>}
              {!isSlotRestricted && <span>Available Strange Attractors: {numPiecesLeft}</span>}
            </div>
          </div>
        </div>


        <div className="disclaimer">
          <strong>Disclaimer: </strong>
          Many aspects of this project are highly experimental.
          While the contracts have been tested extensively, they have not been audited by a third party.
          By buying into the project, you acknowledge and accept the risks involved.

          You can review the code on <a href="https://etherscan.io/address/0x1cA15CCdd91b55CD617a48dC9eEFb98CAe224757">etherscan</a>.
        </div>




      </div>
    </div >
  );
}

export default Mint;
