import './Tools.css';
import React, { useEffect, useState } from 'react';

import { ConnectButton } from '../../components/MyTools/ConnectButton';
import { ProjectionExpert, ProjectionSliders } from '../../components/ProjectionTools';
import { ColormapCreator } from '../../components/ColormapCreator';
import { ethers } from 'ethers'

import { LabeledSlider } from '../../components/MyTools/NumberSlider';
import * as utils2 from '../../components/MyTools/utils2.js';


const addresses = {
  lineRenderer: "0x46eefb5032c055d31cd0653c7daf316fda9c43bb", pointRenderer: "0xdc9b93de5f92c8bcf52b266036710c1be6a39827",
  lorenz: "0xcdf37972dd8a3ff2d1c11c30dcfbc25a2506583b",
  dadras: "0x5fcabe7bc1455a9c15bbf85834f53af1ab8b2544",
  halvorsen: "0x548333e234326cb1efee9ba9ce74ead3b08380fa",
  coullet: "0x8509d347caa93e7db87e0287ee51867bfcb7b9d2"
}

const PRECISION = 96;

const series = {
  lorenz: {
    solver: addresses.lorenz, renderer: addresses.lineRenderer,
      solverParameters: {
          dt: ethers.BigNumber.from(2).pow(PRECISION).div(64),
          skip: 2, numberOfIterations: 2 * 256 * 8
      },
      colorAnchors: [
          { position: 0, red: 5, green: 48, blue: 97 },
          { position: 25, red: 33, green: 102, blue: 172 },
          { position: 51, red: 67, green: 147, blue: 195 },
          { position: 77, red: 146, green: 197, blue: 222 },
          { position: 102, red: 209, green: 229, blue: 240 },
          { position: 127, red: 247, green: 247, blue: 247 },
          { position: 153, red: 253, green: 219, blue: 199 },
          { position: 179, red: 244, green: 165, blue: 130 },
          { position: 204, red: 214, green: 96, blue: 77 },
          { position: 229, red: 178, green: 24, blue: 43 },
          { position: 255, red: 103, green: 0, blue: 31 }
      ],
      size: 20,
  },
  dadras: {
    solver: addresses.dadras, renderer: addresses.pointRenderer,

      solverParameters: {
          dt: ethers.BigNumber.from(2).pow(PRECISION).div(64),
          skip: 1, numberOfIterations: 1 * 256 * 26
      },
      colorAnchors: [{ position: 0, red: 142, green: 1, blue: 82 },
      { position: 26, red: 197, green: 27, blue: 125 },
      { position: 51, red: 222, green: 119, blue: 174 },
      { position: 76, red: 241, green: 182, blue: 218 },
      { position: 102, red: 253, green: 224, blue: 239 },
      { position: 128, red: 247, green: 247, blue: 247 },
      { position: 153, red: 230, green: 245, blue: 208 },
      { position: 178, red: 184, green: 225, blue: 134 },
      { position: 204, red: 127, green: 188, blue: 65 },
      { position: 230, red: 77, green: 146, blue: 33 },
      { position: 255, red: 39, green: 100, blue: 25 }],
      size: 28,
  },
  halvorsen: {
    solver: addresses.halvorsen, renderer: addresses.lineRenderer,

      solverParameters: {
          dt: ethers.BigNumber.from(2).pow(PRECISION).div(32),
          skip: 4, numberOfIterations: 4 * 256 * 8
      },
      colorAnchors: [{ position: 0, red: 0, green: 0, blue: 255 },
      { position: 255, red: 0, green: 255, blue: 128 }],
      size: 10,
  },
  coullet: {
    solver: addresses.coullet, renderer: addresses.lineRenderer,

      solverParameters: {
          dt: ethers.BigNumber.from(2).pow(PRECISION).div(4),
          skip: 2, numberOfIterations: 2 * 256 * 12
      },
      colorAnchors: [{ position: 0, red: 0, green: 0, blue: 3 },
      { position: 32, red: 32, green: 12, blue: 74 },
      { position: 64, red: 87, green: 15, blue: 109 },
      { position: 96, red: 137, green: 34, blue: 105 },
      { position: 128, red: 187, green: 55, blue: 84 },
      { position: 160, red: 228, green: 90, blue: 49 },
      { position: 192, red: 249, green: 142, blue: 8 },
      { position: 224, red: 248, green: 203, blue: 52 },
      { position: 255, red: 252, green: 254, blue: 164 }],
      size: 20,
  },
  // MultiWing4D: {
  //     solverParameters: {
  //         dt: ethers.BigNumber.from(2).pow(PRECISION).div(20),
  //         skip: 2, numberOfIterations: 2 * 256 * 13
  //     },
  //     colorAnchors: [{ position: 0, red: 0, green: 0, blue: 3 },
  //     { position: 32, red: 28, green: 16, blue: 70 },
  //     { position: 64, red: 80, green: 18, blue: 123 },
  //     { position: 96, red: 130, green: 37, blue: 129 },
  //     { position: 128, red: 182, green: 54, blue: 121 },
  //     { position: 160, red: 230, green: 81, blue: 98 },
  //     { position: 192, red: 251, green: 136, blue: 97 },
  //     { position: 224, red: 254, green: 196, blue: 136 },
  //     { position: 255, red: 251, green: 252, blue: 191 }],
  //     size: 20,
  // },
}


import ISvgRenderer from '../../artifacts/contracts/renderers/ISvgRenderer.sol/ISvgRenderer.json'
const rendererAbi = ISvgRenderer.abi



import IAttractorSolver from '../../artifacts/contracts/solvers/IAttractorSolver.sol/IAttractorSolver.json'
const solverAbi = IAttractorSolver.abi




import { SvgWindow } from '../../components/SvgWindow';

const SystemSelector = ({ onChange, ...rest }) => {
  const tokenList = [{ label: "Lorenz", value: "lorenz" },
  { label: "Dadras Momeni", value: "dadras" },
  { label: "Halvorsen", value: "halvorsen" },
  { label: "Coullet", value: "coullet" },


  ];
  const [selected, setSelected] = React.useState("none");

  const handleChange = (e) => {
    setSelected(e.target.value);
    onChange(e);

    console.log(e.target.value)
    console.log(selected)
  }

  return (
    <div {...rest}>
      <label for="tokens">System:</label>
      <select id="tokens" name="tokens" value={selected} onChange={handleChange}>
        <option disabled value="none" style={{ textAlign: 'center' }}> -- select a system -- </option>
        {tokenList != undefined && tokenList.map((pair) => <option key={pair.value} value={pair.value}>{pair.label}</option>)}
      </select>
    </div>
  );
}

const Tools = () => {

  const [solver, setSolver] = React.useState();
  const [renderer, setRenderer] = React.useState();
  const [startingPoint, setStartingPoint] = React.useState();
  const [config, setConfig] = React.useState();

  const [svg, setSvg] = useState("%3Csvg width='1024' height='1024' viewBox='-4096 -4096 8192 8192' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-4096' y='-4096' width='100%25' height='100%25' fill='black'/%3E%3C/svg%3E");
  const [projectionParameters, setProjectionParameters] = useState({
    axis1: [1, 0, 0],
    axis2: [0, 0, 1],
    offset: [3, 3, 3],
  });
  const [colorAnchors, setColorAnchors] = useState([])
  const [renderSize, setRenderSize] = useState(20)

  const onSystemSelect = async (e) => {

    try {
      let systemId = e.target.value;
      console.log(systemId)
      let config = series[systemId];
      setConfig(config);
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      let _solver = new ethers.Contract(config.solver, solverAbi, provider);
      let _renderer = new ethers.Contract(config.renderer, rendererAbi, provider);
      console.log("Solver:", _solver);
      setSolver(_solver);
      setRenderer(_renderer);
      let _projectionParameters = await _solver.getDefaultProjectionParameters(Math.floor(Math.random() * 128));
      setProjectionParameters(utils2.parseProjectionParameters(_projectionParameters));

      let colorAnchors = config.colorAnchors;
      colorAnchors = colorAnchors.map((anchor, id) => { return {...anchor, id: id, color: '#' + utils2.rgbToHex(anchor)}})
      setColorAnchors(colorAnchors);

      setRenderSize(config.size);

      let _startingPoint = await _solver.getRandomStartingPoint(Math.floor(Math.random() * 4096));
      setStartingPoint(_startingPoint);
    } catch (error) {
      console.log(error);
    }
  }

  const [rendering, setRendering] = useState(false);

  const render = async () => {
    setRendering(true);
    try {
      const _projParams = utils2.convertProjectionParameters(projectionParameters);
      console.log("computing sol")
      let solution = await solver.computeSolution(config.solverParameters, startingPoint, _projParams);
      console.log("rendering")
      
      let colormap = utils2.computeColormap(colorAnchors);
      setSvg(await renderer.render(solution, colormap, renderSize));
    } catch (error) {
      console.log(error);
    }
    setRendering(false);
  }

  useEffect(render, [startingPoint]);



  // /**********************************************************/
  // /* Handle chain (network) and chainChanged (per EIP-1193) */
  // /**********************************************************/
  const [chain, setChain] = useState(0);
  window.ethereum.on('chainChanged', (chainId) => {
    window.location.reload()
  });


  /***********************************************************/
  /* Handle user accounts and accountsChanged (per EIP-1193) */
  /***********************************************************/
  const [account, setAccount] = useState('');

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      setAccount('')
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.');
    } else if (accounts[0] !== account) {
      // currentAccount = accounts[0];
      setAccount(accounts[0])
      // Do any other work!
    }
  }

  const setChainId = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    let net = await provider.getNetwork();
    setChain(net.chainId);
  }

  useEffect(setChainId, [account])

  window.ethereum.on('accountsChanged', handleAccountsChanged)

  window.ethereum
    .request({ method: 'eth_accounts' })
    .then(handleAccountsChanged)
    .catch((err) => {
      // Some unexpected error.
      // For backwards compatibility reasons, if no accounts are available,
      // eth_accounts will return an empty array.
      console.error(err);
    });



  return (
    <div className="tools">
      <div>
        <ConnectButton {...{ account, handleAccountsChanged }} />
        {(account !== '' && chain != 1) && <h2 className="rinkeby-message">Please connect Metamask to the mainnet</h2>}
        <div className={(account == '' || chain != 1) ? "disabled" : ""}>
          <div className="tools-container">
            <div className="left">
              <SvgWindow data={svg} loading={rendering} />
              <div className="border token-selector">
                <SystemSelector onChange={onSystemSelect} account={account} />
              </div>

              <div className="button-box">
                <div>
                  <input type="button" onClick={render} value="Render" className="button"></input>
                </div>
                <div>
                  <input type="button" onClick={() => utils2.downloadSVG(svg)} value="Download" className="button"></input>
                </div>
              </div>

              <div className="button-box">
                <div>
                  <input type="button" disabled={true} value="Commit Projection" className="button"></input>
                </div>
                <div>
                  <input type="button" disabled={true} value="Commit Colormap" className="button"></input>
                </div>
              </div>
            </div>


            <div className="right">
              <div className="border">
                <div className="paletteTitle">Projection</div>
              </div>
              <div className="border">
                <ProjectionSliders {...{ projectionParameters, setProjectionParameters }} />
              </div>
              <div className="border">
                <ProjectionExpert {...{ projectionParameters, setProjectionParameters }} />
              </div>
              <div className="border">
              <div className="paletteTitle ">Style</div>
            </div>
            <div className="size-slider-box border">
              <LabeledSlider label="Size" min={0} max={128} value={renderSize} onChange={(e) => setRenderSize(e.target.value)} />
            </div>
            <div className="colormap border">
              <ColormapCreator {...{ colorAnchors, setColorAnchors }} />
            </div>
            </div>
          </div>
        </div>
      </div ></div>
  );
}

export default Tools;
