import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes';
import { NavBar } from './components/NavBar';
import { Footer } from './components/Footer';



const App = () => {
  return (
    <div className="App">
      <div className="content">
        <Router>
          <NavBar />
          <div className="main-content">
            <Routes />
          </div>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
