import './Mint.css';
import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers'

import * as utils from '../../components/MyTools/utils.js';
import * as utils2 from '../../components/MyTools/utils2.js';
import { ConnectButton } from '../../components/MyTools/ConnectButton';

// import { TokenSelector } from '../../components/MyTools/TokenSelector';


export const fetchNonUsedTokensOfSystem = async (account, systemId) => {
  try {
    const contract = utils.getContract();

    let numberOfTokens = await contract.balanceOf(account);

    let tokens = []
    const fetchNonUsedTokenOfSystem = async (idx) => {
      let tokenId = await contract.tokenOfOwnerByIndex(account, idx);
      let _systemId = utils2.getTokenSystemId(tokenId);
      if (_systemId === systemId) {
        let token = await contract.tokens(tokenId);
        if (!token.usedForFullsetToken) {
          let tokenName = await contract.getTokenName(tokenId);
          tokens.push({ label: tokenName, value: tokenId })
        }
      }
    }

    const txs = []
    for (let idx = 0; idx < numberOfTokens; ++idx) {
      txs.push(fetchNonUsedTokenOfSystem(idx))
    }
    await Promise.all(txs)

    tokens = tokens.sort((left, right) => left.value - right.value)

    console.info('Nonused tokens fetched:', tokens);

    return tokens;
  } catch (error) {
    console.error(error);
  }
}

export const TokenSelector = ({ account, onChange, systemId }) => {
  const [tokenList, setTokenList] = React.useState([]);
  const [selected, setSelected] = React.useState("none");

  React.useEffect(async () => {
    setTokenList(await fetchNonUsedTokensOfSystem(account, systemId));
  }, [account]);

  const handleChange = (e) => {
    setSelected(e.target.value);
    onChange(e);
  }

  return (
    <div className="token-selector-container">
      <label className="token-selector-label">System {systemId}:</label>
      <select className="token-selector" name="tokens" value={selected} onChange={handleChange}>
        <option disabled value="none" style={{ textAlign: 'center' }}> -- select a token -- </option>
        {tokenList != undefined && tokenList.map((pair) => <option key={pair.value} value={pair.value}>{pair.label}</option>)}
      </select>
    </div>
  );
}

const FullsetMint = ({ account }) => {
  const [fullsetMintEnabled, setFullsetMintEnabled] = useState(0);

  const [tokenSacrifice1, setTokenSacrifice1] = useState(0);
  const [tokenSacrifice2, setTokenSacrifice2] = useState(0);
  const [tokenSacrifice3, setTokenSacrifice3] = useState(0);
  const [tokenSacrifice4, setTokenSacrifice4] = useState(0);


  const getSlotData = async () => {
    try {
      const contract = utils.getContract();
      let enabled = await contract.isFullsetMintEnabled();
      setFullsetMintEnabled(enabled);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(getSlotData, [account])

  const doFullsetMint = () => {
    utils.mintFullset([tokenSacrifice1, tokenSacrifice2, tokenSacrifice3, tokenSacrifice4]);
  };

  const canMint = () => {
    return fullsetMintEnabled && tokenSacrifice1 > 0
      && tokenSacrifice2 > 0
      && tokenSacrifice3 > 0
      && tokenSacrifice4 > 0
      ;
  }


  return (
    <div className="mint-container">

      <div className="token-selector">
        <TokenSelector onChange={e => setTokenSacrifice1(e.target.value)} account={account} systemId={1} />
        <TokenSelector onChange={e => setTokenSacrifice2(e.target.value)} account={account} systemId={2} />
        <TokenSelector onChange={e => setTokenSacrifice3(e.target.value)} account={account} systemId={3} />
        <TokenSelector onChange={e => setTokenSacrifice4(e.target.value)} account={account} systemId={4} />
      </div>

      <div className="mint-button-container">
        <input type="button" value="Complete Collection" className="mint-button" onClick={doFullsetMint} disabled={!canMint()}></input>
      </div>
    </div>
  );

}



const Mint = () => {

  // /**********************************************************/
  // /* Metamask boilerplate                                   */
  // /**********************************************************/
  window.ethereum.on('chainChanged', (chainId) => window.location.reload())

  const [account, setAccount] = useState('');

  const handleAccountsChanged = (accounts) => {
    console.log('account changed');
    if (accounts.length === 0) {
      setAccount('')
      console.log('Please connect to MetaMask.');
    } else if (accounts[0] !== account) {
      setAccount(accounts[0])
    }
  }

  window.ethereum.on('accountsChanged', handleAccountsChanged)

  window.ethereum
    .request({ method: 'eth_accounts' })
    .then(handleAccountsChanged)
    .catch((err) => {
      console.error(err);
    });



  return (
    <div className="fullmint">

      <div className="description">
        <h4>Complete System</h4>
        <p>
          Connect your wallet and mint your Strange Attractor by clicking the button below.
        </p>
      </div>

      <div>
        <ConnectButton {...{ account, handleAccountsChanged }} />
      </div>


      <div className="main-interactive-container">


        {account != '' && <FullsetMint {...{ account }} />}
      </div>
    </div>
  );
}

export default Mint;
