import React from 'react';
import { Home } from './views/Home';
import { About } from './views/About';
import { Details } from './views/Details';
import { Tools } from './views/Tools';
import { ToolsDemo } from './views/ToolsDemo';
import { Mint } from './views/Mint';
import { MintFullset } from './views/MintFullset';
import { Admin } from './views/Admin';
import { Quest } from './views/Quest';
import { Quest2 } from './views/Quest2';
import { Route, Switch, Redirect } from 'react-router-dom';

export const Routes = () => {
  return (
    <div className="main-content">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home">
          <Redirect to="/" />
        </Route>
        <Route exact path="/about" component={About} />
        <Route exact path="/details" component={Details} />
        <Route exact path="/tools2" component={Tools} />
        <Route exact path="/tools" component={ToolsDemo} />
        <Route exact path="/mint" component={Mint} />
        <Route exact path="/mintFullset" component={MintFullset} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/quest" component={Quest} />
        <Route exact path="/quest2" component={Quest2} />
      </Switch>
    </div>
  );
};